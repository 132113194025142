<template>
  <div>
    <h1>Welcome to Vue</h1>
  </div>
</template>

<script>
  export default {

  }
</script>

<style>

</style>